@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

//********************************************************
//                    Variables
//********************************************************

// Several accent colors, choose one or create your own!
$accent-color: #008282;
$font-color: #0d0d0d;
$blockquote-font-color: #595959;
$background-color: #f2f2f2;
$post-panel-color: #cccccc;
$post-border-color: #666666;
$footer-background-color: #75715e;
$footer-font-color: #ffffff;
$header-background-color: #75715e;
$header-border-color: #75715e;
$header-top-border-color: #75715e;
$header-bottom-border-color: #75715e;
$header-font-color: #ffffff;
$header-hover-font-color: #0d0d0d;
$hamburger-menu-background-color: #ffffff;
$hamburger-menu-icon-color: #0d0d0d;
$note-color: #0a9bf5;
$warning-color: #ff6600;
$note-warning-border-color: #666;
$highlight-caption-background-color: #292929;
$highlight-caption-font-color: #008282;
$table-background-color: #b3b3b3;
$table-border-color: #5e5e5e;
$social-color: #ffffff;
$social-hover-color: #0d0d0d;
$share-button-color: #0d0d0d;
// use this or pick any from /css/pics/background folder or from transparenttextures.com
$background-pattern: 'grey-sandbag.png';

// ---=== ORIGINAL SETTINGS BELOW: ===---
// Several accent colors, choose one or create your own!
//   $accent-color: #3CA2A2;    // original =) #308282
//$accent-color: #C38FD6;  // velvet
//$accent-color: #8FD6B3;  // greenish
//$accent-color: #35B4DE;  // bluish
//$accent-color: #D2E354;  // yellowish
//$accent-color: #52B54B;  // green

//$font-color: #dddddd;
//$background-color: #292929;
//$post-panel-color: #39393a;
//$footer-background-color: #292c2f;
//$note-color: #87CEFA;
//$warning-color: #ffff00;
// use this or pick any from /css/pics/background folder or from transparenttextures.com
//$background-pattern: 'random-grey-variations.png';

//********************************************************
//                  Common styles
//********************************************************
body {
  color: $font-color;
  background: $background-color url(pics/background/#{$background-pattern});
}
.card {
  background-color: $post-panel-color;
  box-shadow: 0 0 0 0, 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 20px;
  font-size: 14px;
}
.main-page {
  width: 75%;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

img {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

img + em {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

blockquote {
  color: $blockquote-font-color;
//  color: #bbb
  font-size: 1em;
}

a {
  color: $accent-color;
}

//********************************************************
//                    Social Links
//********************************************************
ul.social-media {
  text-align: center;
  padding: 0 0 10px 0;
  li {
    display: inline-block;
  }
  li > a {
    width: 45px;
    height: 45px;
    @include transition(color 0.2s);
    display: inline-block;
    color: $social-color;
    &:hover {
      color: $social-hover-color;
    }
  }
}


//********************************************************
//                    Share
//********************************************************
.share-bar{
  text-align: right;
}

ul.share-buttons {
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    .fa-facebook-f,
    .fa-twitter,
    .fa-google-plus-g,
    .fa-linkedin-in,
    .fa-envelope,
    .fa-pinterest-p {
      color: $share-button-color
    }
    a {
      text-decoration: none;
    }
    -webkit-transition: -webkit-transform .4s;
    -moz-transition: -moz-transform .4s;
    -ms-transition: -ms-transform .4s;
    -o-transition: -o-transform .4s;
    transition: transform .4s;
  }

  // Square frame
  li.share-facebook .fa-square { color: #3B5998;}
  li.share-twitter .fa-square { color: #55acee;}
  li.share-google-plus .fa-square { color: #dd4b39;}
  li.share-linkedin .fa-square { color: #4875B4;}
  li.share-pinterest .fa-square { color: #BD081C;}
  li.share-envelope .fa-square { color: #3B5998;}
  // Circle frame
  li.share-facebook .fa-circle { color: #3B5998;}
  li.share-twitter .fa-circle { color: #55acee;}
  li.share-google-plus .fa-circle { color: #dd4b39;}
  li.share-linkedin .fa-circle { color: #4875B4;}
  li.share-pinterest .fa-circle { color: #BD081C;}
  li.share-envelope .fa-circle { color: #3B5998;}

  li:hover {
    -webkit-transform: scale(1.3,1.3);
    -moz-transform: scale(1.3,1.3);
    -ms-transform: scale(1.3,1.3);
    -o-transform: scale(1.3,1.3);
    transform: scale(1.3,1.3);
  }
}

//********************************************************
//                    Sidebar
//********************************************************
.sidebar li {
  margin-top: .7em;
  line-height: 1em;
}
ul.sideBarTags {
  list-style: none;
  margin-left: -1em;
/*  li:before {
     content: '\f02b';
     font-family: 'FontAwesome';
     float: left;
    margin-left: -1.5em;
  }*/
}

//********************************************************
//                    Post
//********************************************************
h2.post-title {
  padding-top: 10px;
  text-align: left;
}
div.post-meta {
  border-width: 1px 0;
  border-color: $post-border-color;
  border-style: solid;
  margin: 0 0 20px;
  ul {
    display: inline;
    padding-left: 0;
    float: right;
    margin: 0;
  }
  ul > li {
    display: inline;
    padding: 0 0 5px 20px;
  }
  .post-time {
    display: inline;
  }
}
.post-footer {
  height: 40px;
  display: table;
  width: 100%;
  position: relative;
  margin-top: 32px;
  text-align: center;
}

//********************************************************
//                    Note / Warning
//********************************************************
blockquote{
  border-left: 2px solid $note-warning-border-color;
  padding-left: 20px;
  font-style: italic;
}

blockquote.note p,
blockquote.warning p{
  height: 48px;
  vertical-align: middle;
  padding: 10px;
  font-style: normal;
}

.note {
  font-style: normal;
  position:relative;
  border: 0;
  padding-left: 30px;
}
.note:before {
  font-style: normal;
  content: "\f06a";
  font-size: 2em;
  font-family: FontAwesome;
  left: 0;
  position:absolute;
  top: 0;
  color: $note-color;
}
.warning:before {
  content: "\f071";
  color: $warning-color;
}

//********************************************************
//                    Header
//********************************************************
nav {
  background-color: $header-background-color;
  border-color: $header-border-color;
  font-size: 18px;
  font-family: "Ubuntu", sans-serif;
  padding: 0 10px !important;
  border-top: 3px $header-top-border-color solid;
  border-bottom: 1px $header-bottom-border-color solid;

  .navbar-brand, .nav-link {
   color: $header-font-color;
   @include transition(color 0.31s);
   transition: all .7s ease;
  }
  .navbar-brand:hover, .nav-link:hover{
    color: $header-hover-font-color;
  }
  .navbar-brand {
    font-size: 30px;
    color: $header-font-color;
  }
  .navbar-nav {
    margin-left: auto;
  }
  .navbar.shrink {
    min-height: 15px !important;
  }
  .navbar-toggler {
    margin: 13px 15px 13px 0;
    background: $hamburger-menu-background-color;
  }
  .navbar-toggler-icon {
    color: $hamburger-menu-icon-color; 
    font-size: 15px;
    text-align: center;
    margin-top: 7px;
  }
  .shrink .navbar-toggler {
    padding: 4px 5px;
    margin: 8px 15px 8px 0;
  }
  .navbar-lang-selector {
     margin-left: 10px;
  }
  .nav-lang-selector-item {
    color: $header-hover-font-color;
    @include transition(color 0.31s);
    transition: all .7s ease;
  }
  .nav-lang-selector-item:hover {
    text-decoration: none;
    color: $header-font-color;
  }
}

nav.shrink {
  a {
    font-size: 15px;
    padding: 0 10px !important;
  }
  .navbar-brand {
    font-size: 25px;
    height: inherit;
  }
}

//********************************************************
//                 Table
//********************************************************

.post-content > table {
  background-color: $table-background-color;
  margin: 0 auto 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  tbody {
    box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
  }

  th {
    text-transform: uppercase;
    text-align: center;
    padding: .5em .75em;
  }
  td {
    padding: .5em;
  }

}

table.wide {
  width: 100%;
  max-width: 100%;
}

table.inner-borders {
  border-collapse: collapse;
  border-style: hidden;
  td {
    border: 1px solid $table-border-color;
  }
}

//********************************************************
//                    Footer
//********************************************************
.footer {
  text-align: center;
  color: $footer-font-color;
}

.footer-distributed {
  background-color: $footer-background-color;
  font: normal 16px sans-serif;
  padding: 20px 25px;
  margin-top: 80px;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
}

.box h3{
	text-align:center;
	position:relative;
	top:80px;
}
.box {
	width:70%;
	height:200px;
	background:#FFF;
	margin:40px auto;
}
.wrapper{
  padding-top: 80px;
  min-height: 100%;
  position: relative;
}
.content{
  padding-bottom: 250px;
}

.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  padding-top: 50px;
  margin-top: -50px;
}

//********************************************************
//       Custom classes to apply to block elements,
//  more info: http://pavelmakhov.com/2015/12/images-in-jekyll
//********************************************************
.key {
  font-size: 1.0em;
  box-shadow: 1px 0 1px 0 #555, 0 1px 0 2px #888, 0 2px 0 2px #777;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 2px 3px;
  padding: 0px 5px;
}
.center-image
{
  margin: 0 auto;
  display: block;
}
.filename{
  background-color: $highlight-caption-background-color;
  color: $highlight-caption-font-color;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
  border-left: 0;
  bottom: -2px;
  position: relative;
  padding: 0 0 2px 1em;
  p {
    margin: 0;
  }
}

//********************************************************

@media (min-width: 1000px) {
  .twoRow .navbar-collapse {
    clear: left;
  }
  .footer-distributed .footer {
    text-align: center;
  }
  .footer-distributed .footer {
    float: none;
    margin: 0 auto 20px;
  }
  .footer-distributed .footer {
    line-height: 1.8;
  }
}

